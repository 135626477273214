// Brand colors
$logo-color: #fa7548;
$logo-color-shadow: rgba(250, 117, 72, 0.75);

// Color of shot markers on the rim diagram
$shot-hit-color: #080;
$shot-color: #008;
$shot-miss-color: #f00;

$backboard-color:  #aaa;

$background-color: #fff;
$navbar-color: #9d9d9d;
 
.no-float .btn {
    float: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.table-striped > tbody > tr:nth-child(2n) > td, .table-striped > tbody > tr:nth-child(2n) > th {
  background: rgba(255, 255, 255, .8);
}

.table-striped > tbody > tr:nth-child(2n+1) > td, .table-striped > tbody > tr:nth-child(2n+1) > th {
  background: rgba(249, 249, 249, .8);
}

.table-striped > tbody > tr:nth-child(2n+1), .table-striped > tbody > tr:nth-child(2n+1) {
  background-color: transparent;
}

.table-striped > thead {
  background: rgba(255, 255, 255, .8);
}

.archived-player-row {
  background: rgba(255, 239, 184, .4) !important;
}

.archived-player-box {
  background: rgba(255, 239, 184, 1) !important;
}

.archived-player-row > td {
  background: rgba(255, 239, 184, .4) !important;
}

.players-table td.text {
  max-width: 100px;
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 100%;
  }
}

@media(max-width:767px){
  .button-width-with-padding {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

@media(max-width:767px){
  .row-custom-padding {
    margin-right: 3px;
    margin-left: 3px;
  }
}

@media(min-width:767px){
  .row-custom-padding {
    padding-top: 15px;
    margin-right: 0;
    margin-left: 0;
  }
}


// Editable team roster
@media (max-width: 1200px) {
  .player-card {
    width: 150px;
    height: 150px;
    margin: 10px;
    .image {
      height: 72px;
    }
    .name {
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .data {
      font-size: 12px;
    }
  }
  .edit-btn {
    font-size:11px;
    .fa {
      font-size:14px;
      margin-left: -3px;
    }
    top: -16px;
    left: 5px;
  }
  .player-box-content {
    margin-top: 6px;
  }
}

@media (min-width: 1200px) {
  .player-card {
    width: 200px;
    height: 200px;
    .image {
      height: 100px;
    }
    .name {
      font-size: 18px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .data {
      font-size: 16px;
    }
    .edit-btn {
      font-size:14px;
      .fa {
        font-size:18px;
        margin-left: 2px;
      }
      top: -21px;
      left: 2px;
    }
  }
  .player-box-content {
    margin-top: 14px;
  }
}


// Editable team roster
.player-card {
  @extend .data-box;
  position: relative;
  padding: 10px;
  .image {
    background-color: #ddd;
    margin-bottom: 4px;
    display: block;
    transition: .3s ease;
    backface-visibility: hidden;
  }
  .middle {
    transition: .3s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%)
  }
}

.player-card:hover .image {
  opacity: 0.3;
}

.player-card:hover .middle {
  opacity: 1;
}


.button {
  -moz-appearance: button;
  /* Firefox */
  -webkit-appearance: button;
  /* Safari and Chrome */
  padding: 10px;
  margin: 10px;
  width: 70px;
}
.drop-box {
  background: #F8F8F8;
  border: 5px dashed #DDD;
  height: 75px;
  text-align: center;
  padding-top: 25px;
  margin: 10px;
}
.dragover {
  border: 5px dashed blue;
}

.image-recommended-size-text {
  font-size: 10px;
  color: #afafaf;
  font-weight: normal;
}

.image-select-box {
  position: absolute;
  left: 65px;
  top: 44%;
  z-index: 10;
  background-color: rgb(248, 248, 248);
  border: 2px dashed #dddddd;
  padding: 10px 19px;
  border-radius: 12px;
  font-size: 14px;
}

.team-image-in-player-box {
  height: 190px;
  opacity: 0.5;
  position: relative;
  z-index: 1;
  right: 50px;
}

.player-image-in-player-box {
  height: 190px;
  position: relative;
  z-index: 2;
  left: 30px;
}

.add-player-image-box {
  height: 190px;
}

.image-preview-label {
  font-size: 14px;
  color: #afafaf;
  font-weight: normal;
  position: absolute;
  z-index: 50;
}

.image-preview {
  max-height: 100px;
  object-fit: contain;
  border: 2px #666 solid;
  background-color: #EEE;
}

.uploaded-player-image-in-player-box {
  height: 190px;
  position: relative;
  z-index: 2;
  left: 30px;
  max-width: 70%;
  object-fit: contain;
}

.player-image-in-a-player-box-contained {
  max-width: 100%;
  object-fit: contain;
}
// Large displays
@media (min-width: 1200px) {
    .node-card {
        width: 180px;
        height: 180px;
        margin: 15px;
        .status-marker {
            bottom: 12px;
            left: 8px;
        }
        .node-name {
            top: -47px;
            font-size: 20px;
            line-height: 20px;
        }
    }
}

// Other displays
@media (max-width: 1200px) {
    .node-card {
        width: 150px;
        height: 150px;
        margin: 10px;
        .node-name {
            top: -40px;
            font-size: 16px; // line-height: 16px;
        }
        .status-marker {
            bottom: 8px;
            left: 5px;
        }
    }
}

.node-card {
    display: inline-block;
    float: none;
    background-color: #eee;
    border: 2px #666 solid;
    box-shadow: 6px 6px 3px #666;
    user-select: none;
    position: relative;
    padding: 10px;
    .node-name {
        position: relative;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .status-marker {
        position: absolute;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        float: left;
        margin-right: 10px;
    }
}

.hoop-enabled {
  opacity: 1;
}

.hoop-disabled {
  pointer-events: none;
  opacity: 0.4;
}
.court-floor {
    width: 1400px;
    height: 800px;
    opacity: 0.8;
}

#dashboard-left-pane {
    position: absolute;
    top: 60px;
    left: 180px;
    width: 400px;
}

.report-zone {
    stroke: red;
    fill: none;
    stroke-width: 2px;
    opacity: 0.1;
}

.stats-box {
    @extend .data-box;
    height: 280px;
    width: 400px;
    padding: 8px;
    .filtered-results {
        margin-top: 8px;
        text-align: center;
        font-size: 20px;
        color: black;
    }
}

.stats-box-alt {
    @extend .data-box-alt;
    height: 280px;
    width: 400px;
    padding: 8px;
    .hideIcon{
        opacity: 0;
    }
    .filtered-results {
        margin-top: 10px;
        text-align: center;
        font-size: 20px;
        color: black;
        .value-number {
            text-align: right;
            padding-left: 0px;
        }
    }
}

.stats-box-xs {
    @extend .data-box;
    width: 85vw;
    height: 220px;
    padding: 8px;
    .player-image {
        height: 49px;
        width: 49px;
        object-fit: cover;
        border-radius: 50%;
    }
    .data-box-text {
        margin: 5px;
    }
    .player-name-heading {
        font-size: 21px;
    }
    .shots-start-end-date {
        font-size: 11px;
        color: #000;
    }
    .percentage {
        @extend .data-box-text;
        color: black;
        font-size: 32px;
        font-weight: bolder;
    }
    .shots-total-text {
        @extend .data-box-text;
        color: black;
        font-size: 16px;
        font-weight: bolder;
    }
    .shot-breakdown-text {
        @extend .shots-total-text;
    }
    .results-table {
        margin-top: 5px;
        margin-left: 10px;
        font-size: 14px;
        text-align: center;
        padding: 0px;
        .value-header {
            font-size: 12px;
            color: gray; // font-weight: bolder;
            text-align: right;
            padding-right: 15px;
        }
        .value {
            text-align: left; // font-weight: bolder;
        }
        .value-label {
            font-size: 12px;
            color: gray;
            text-align: left;
            padding-top: 2px;
            padding-right: 0px;
        }.value-number {
             text-align: right;
             padding-left: 0px;
         }
    }
}

.basket-canvas {
    @extend .data-box;
    height: 400px;
}

.basket-canvas-alt {
    @extend .data-box-alt;
    height: 400px;
}

.basket-canvas-xs {
    @extend .data-box;
    width: 85vw;
    height: 85vw;
}

.shot-timeline {
    @extend .data-box;
    height: 140px;
    width: 1500px;
}

.shot-timeline-responsive {
    @extend .data-box;
    height: 100px;
    width: 100%;
    margin: 0;
}

.shot-timeline-responsive-alt {
    @extend .data-box-alt;
    height: 100px;
    width: 100%;
    margin: 0;
}

@media (max-width: 767px) {
    .responsive-court {
        height: calc(100vh - 80px);
    }
}

@media (min-width: 768px) {
    .responsive-court {
        height: calc(100vh - 190px);
    }
}

.responsive-court-background {
    display: block;
    position: relative;
}

.responsive-court-background::after {
    content: "";
    background: url('/static/images/court-floor.png');
    opacity: 0.3;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
}

#timeline-chart {
    height: 100%;
}

.shot-hit-basket {
    .border {
        stroke: black;
        stroke-width: 6;
        fill: none;
    }
    .backboard {
        stroke: $backboard-color;
        fill: $backboard-color;
    }
    .rim {
        stroke: $logo-color;
        stroke-width: 5;
        fill: none;
    }
    .rim-holder {
        stroke: $logo-color;
        stroke-width: 5;
        fill: $logo-color;
    }
    .net {
        stroke: #ddd;
        stroke-width: 2;
        fill: none;
    }
    .shot {
        stroke: $shot-color;
        fill: $shot-color;
    }
    .shot-hit {
        @extend .shot;
        fill: $shot-hit-color;
    }
    .shot-miss {
        @extend .shot;
        fill: $shot-miss-color;
    }
    .shot-hit-alt {
        @extend .shot;
        stroke: #0b8700;
        fill: #5cb85c;
        stroke-opacity: 0.5;
        stroke-width: 3px;
    }
    .shot-miss-alt {
        @extend .shot;
        fill: #cc3c38;
        stroke: #f4423d;
        stroke-width: 3px;
    }
    .shot-miss-bg {
        @extend .shot;
        stroke-width: 6px;
        stroke: black;
    }
    .shot-miss-bg-alt {
        @extend .shot;
        stroke-width: 6px;
        stroke: #ca322e;
    }
    .shot-miss {
        @extend .shot;
        stroke-width: 5px;
        stroke: $shot-miss-color;
    }
    .hairline {
        stroke: black;
        stroke-width: 1;
    }
    .offset-line {
        stroke: black;
        stroke-width: 3;
    }
    .offset-marker {
        stroke: red;
        stroke-width: 7;
    }
    .svg-stat-text {
        fill: red;
        font-size: 36px;
        text-anchor: middle;
    }
}

#court-svg {
    // #selection {
    //     stroke: black;
    //     stroke-width: 30;
    //     stroke-dasharray: 100, 100;
    //     fill: #31b0d5;
    //     opacity: 0.2;
    // }    
    .svg-border {
        stroke: black;
        stroke-width: 6;
        fill: none;
    }
    .shot-basket {
        stroke: $logo-color;
        fill: $logo-color;
    }
    .shot-rim {
        stroke: $logo-color;
        stroke-width: 25;
        fill: none;
    }
    .normalized-shot-fade-out {
        -webkit-animation-name: fade-out-anim; /* Safari 4.0 - 8.0 */
        -webkit-animation-duration: 0.6s; /* Safari 4.0 - 8.0 */
        animation-name: fade-out-anim;
        animation-duration: 0.6s;
        animation-fill-mode: forwards;
    }
    /* Safari 4.0 - 8.0 */
    @-webkit-keyframes fade-out-anim {
        from {opacity: 1;}
        to {opacity: 0.22}
    }
    
    /* Standard syntax */
    @keyframes fade-out-anim {
        from {opacity: 1;}
        to {opacity: 0.22}
    }

    .normalized-shot-fade-out-in-region {
        -webkit-animation-name: fade-out-anim; /* Safari 4.0 - 8.0 */
        -webkit-animation-duration: 0.6s; /* Safari 4.0 - 8.0 */
        animation-name: fade-out-anim;
        animation-duration: 0.6s;
        animation-fill-mode: forwards;
    }
    /* Safari 4.0 - 8.0 */
    @-webkit-keyframes fade-out-anim-in-region {
        from {opacity: 1;}
        to {opacity: 0.3}
    }
    
    /* Standard syntax */
    @keyframes fade-out-anim-in-region {
        from {opacity: 1;}
        to {opacity: 0.3}
    }
    // .normalized-shot-fade-in {
    //     -webkit-animation-name: fade-in-anim; /* Safari 4.0 - 8.0 */
    //     -webkit-animation-duration: 0.6s; /* Safari 4.0 - 8.0 */
    //     animation-name: fade-in-anim;
    //     animation-duration: 0.6s;
    //     animation-fill-mode: forwards;
    // }
    // /* Safari 4.0 - 8.0 */
    // @-webkit-keyframes fade-in-anim {
    //     from {opacity: 0.3;}
    //     to {opacity: 1}
    // }
    
    // /* Standard syntax */
    // @keyframes fade-in-anim {
    //     from {opacity: 0.3;}
    //     to {opacity: 1}
    // } // Shot base style
    .shot {
        stroke-width: 6px;
    }
    .shot-hit {
        @extend .shot;
        stroke: $shot-hit-color;
        fill: $shot-hit-color;
    }
    .shot-miss {
        @extend .shot;
        stroke: $shot-miss-color;
        fill: $shot-miss-color;
    } // Shot Origin base style
    .shot-origin {
        stroke: $shot-color;
        fill: $shot-color;
    }

    .shot-origin-hit {
        @extend .shot-origin;
        fill: $shot-hit-color;
    }
    .shot-origin-hit-alt {
        @extend .shot-origin;
        stroke: #056d00;
        fill: #20a520;
        stroke-opacity: 0.5;
        stroke-width: 23px;
    }    

    .shot-origin-miss {
        @extend .shot-origin;
        stroke-width: 30px;
        stroke: $shot-miss-color;
    }
    .shot-origin-miss-alt {
        @extend .shot-origin;
        fill: #cc3c38;
        stroke: #f4423d;
        stroke-width: 23px;
    }
    .shot-origin-miss-bg {
        @extend .shot-origin;
        stroke-width: 40px;
        stroke: black;
    }
    .shot-origin-miss-bg-alt {
        @extend .shot-origin;
        stroke-width: 55px;
        stroke: #981e1a;
    }
    .court-line {
        stroke-width: 3;
        stroke: black;
        fill: none;
    }
    .court-rim {
        stroke: $logo-color;
        stroke-width: 2;
        fill: none;
    }
    .court-rim-holder {
        stroke: none;
        fill: $logo-color;
    }
    .court-backboard {
        stroke: none;
        fill: $logo-color;
    }
    .draggable-origin-box {
        .region {
            stroke: black;
            fill: #31b0d5;
            stroke-width: 1;
            fill-opacity: 0.2;
        }
        .shape-button {
            cursor: pointer;
            stroke: black;
            stroke-width: 1;
            fill: $logo-color;
            stroke-opacity: 0.3;
            fill-opacity: 0.15;
        }
        .shape-button:hover {
            fill: $logo-color;
            stroke-opacity: 0.7;
            fill-opacity: 0.8;
        }
    }
    #offset-arrow {
        stroke: #000;
        stroke-width: 8;
    }
    #offset-circle {
        stroke: black;
        fill: none;
        stroke-width: 6;
    }
    .svg-player-text {
        font-family: Calibri;
        font-size: 30px;
        font-weight: bold;
        text-anchor: middle;
    }
    .svg-stat-text {
        font-family: Calibri;
        font-weight: bold;
        font-size: 20px;
    }
}

//Display fix for Safari browser
.static-position-buttons {
    [class*='btn'] {
        position: static;
    }
}

//Display fix for Safari browser
#svg-boxes {
    [class*='col'] {
        position: static;
    }
}

//Display fix for Safari browser
#svg-boxes-xs {
    [class*='col'] {
        position: static;
    }
}

@media (min-width: 1200px) {
    #screen-size-buttons {
        .big-size-btn {
            padding: 10px 16px;
            font-size: 18px;
            line-height: 1.3333333;
            border-radius: 6px;
        }
    }
}

.btn-video {
    color: #fff;
    background-color: #909090;
    border-color: #3a3a3a;
    transition: all 0.3s ease;
}

.btn-video:hover {
  color: #fff;
  background-color: #5E5E5E;
}

.label-video {
  background-color: #909090;
}

.sector-label-text {
    background: rgba(43, 164, 200, 0.65);
    border-radius: 500px;
    color: #ffffff;
    padding: 4px 4px;
    font-size: 9px;
    font-weight: 100;
    font-family: 'Verdana', sans-serif;
}

.download-label-text {
    background: $logo-color;
    border-radius: 500px;
    color: #ffffff;
    padding: 4px 4px;
    font-size: 9px;
    font-weight: 100;
    font-family: 'Verdana', sans-serif;
}

.front-view-btn-disable {
    background-color: rgba(40, 96, 144, 0.3) !important;
    border-color: rgba(32, 77, 116, 0.3) !important;
    pointer-events: none;
}

.session-id {
    -webkit-touch-callout: all;
    -webkit-user-select: all;
    -khtml-user-select: all;
    -moz-user-select: all;
    -ms-user-select: all;
    user-select: all;
    right: 26px;
    text-align: right;
    top: 3px;
    color: #9c9c9c;
}
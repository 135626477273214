// Brand colors
$logo-color: #fa7548;
$logo-color-shadow: rgba(250, 117, 72, 0.75);

.main-title {
  background: rgba(255,255,255,.8);
  padding: 10px;
  border-radius: 10px;
}

.separator {
  border: 1px solid #ccc;
  margin: 10px
}

.court-line {
  stroke-width: 3px;
  stroke: black;
  fill: none;
}
.court-rim {
  stroke: $logo-color;
  stroke-width: 2px;
  fill: none;
}
.court-rim-holder {
  stroke: none;
  fill: $logo-color;
}
.court-backboard {
  stroke: none;
  fill: $logo-color;
}
.court-center {
  stroke-width: 3px;
  stroke: black;
  fill: white;
}
.basket {
  stroke: #fa7548;
  stroke-width: 4px;
  fill: none;
}
.basket-id {
  text-anchor: middle;
  stroke: $logo-color;
  fill: $logo-color;
  font-size: 100px;
  font-weight: bold;
  dominant-baseline: central;
}
.side-basket-id {
  stroke: $logo-color;
  fill: $logo-color;
  font-size: 60px;
  font-weight: bold;
  dominant-baseline: central;
}

.btn-enabled {
  opacity: 1;
}

.btn-disabled {
  pointer-events: none;
  opacity: 0.4;
}

.card {
  animation: 0.3s linear 0s both fadeIn;
  position: relative;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  min-width: 0;
  overflow-wrap: break-word;
  background-color: rgb(255, 255, 255);
  background-clip: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.125);
  border-image: initial;
  border-radius: 0.75rem;
  margin: 10px 0;
}

.card-header {
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  padding: 0.75rem 1.25rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-body {
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  padding: 1.25rem;
}

#CourtBuilderForm {
  .dropdown-menu {
    position: relative;
  }
  .color-picker-panel {
    position: relative;
  }
}

.animated-court {
  transition: .3s ease;
}

.court-logo {
  transition: 0s;
}

.with-transition {
  transition: .3s ease;
}

.color-preview {
  height: 30px;
  transition: .3s ease;
  border-radius: 4px;
  border: 2px #5a5a5a solid;
}

.color-preview-label {
  font-size: 10px;
  font-weight: 100;
  color: #717171;
}

.uuid-label {
  margin: 10px 0;
  font-size: 10px;
  font-weight: 100;
}

.uuid-input {
  width: 58%;
  font-size: 8px;
  height: 26px;
  margin: 10px 0;
}

.full-court {
  opacity: 1;
}
.full-court.ng-enter {
  -webkit-transition: .3s ease;
  transition: .3s ease;
  opacity: 0;
}
.full-court.ng-enter-active {
  opacity: 1;
}

.affix {
  top:50px;
  position: fixed;
  width: 100%;
  background-color:white;
  z-index:777;
}

.court-image-select-box {
  background-color: rgb(248, 248, 248);
  border: 2px dashed #dddddd;
  padding: 10px 19px;
  border-radius: 12px;
  font-size: 14px;
  margin-top: 10px;
}
// Order matters!
@import 'base/colors';
@import 'base/typography';
@import 'base/mixins';
@import 'base/nodes';
@import 'base/sessions';
@import 'base/session';
@import 'base/fault';
@import 'base/player';
@import 'base/account-settings';
@import 'base/court_builder';
@import 'base/video';
@import 'base/manual-assign';
//
// General
//
body {
  padding-top: 70px;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.no-wrap {
  white-space: nowrap;
}

.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dashboard-team-logo {
  position: absolute;
  top: 55px;
  left: 0px;
  width: 256px;
  height: 256px;
  opacity: 0.6;
  z-index: -1;
}

.dropdown-menu>ul>li>a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333333;
    white-space: nowrap
}

.caret-hover-color {
  color: $navbar-color;
}

a.caret-hover-color:hover,  a.caret-hover-color:active {
  color: $background-color;
  text-decoration: none;
}

.navbar-brand>.open>a {
  color: $background-color;
  text-decoration: none;
}

.navbar-brand>.bs-actionsbox {
  padding: 4px 8px;
}

.login-box {
  width: 360px;
  margin: 7% auto;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  -webkit-box-shadow: 0px 1px 1px 0 #969696;
  -moz-box-shadow: 0px 1px 1px 0 #969696;
  box-shadow: 0px 1px 1px 0 #969696;
}

.grey-background {
  background-color: #dedede !important;
}
.logo-box {
  margin: 12px 0 31px 0;
}
.logo-image {
  width: 100%;
  object-fit: contain;
  height: 80px;
}

.rspct-login-btn {
  background-color: #ed6e16;
  color: #fff;
  width: 100%;
  margin-bottom: 22px;
  margin-top: 5px;
}

.rspct-login-btn:hover {
  color: #ffffff;
}

.rspct-login-btn:focus {
  color: #ffffff;
}
//@media (min-width: 992px) {
//  .desktop-login {
//    position: relative;
//    top: 75px;
//    left: -250px;
//  }
//}
@media (max-width: 992px) {
  .login-video {
    display: none;
  }
}
@media (min-width: 992px) {
  .login-video {
    position: absolute;
    width: 100%;
    height: 750px;
  }
}
.dark-gradient {
  background: #666666; /* Old browsers */
  background: -moz-linear-gradient(top, #666666 0%, #0e0e0e 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #666666 0%, #0e0e0e 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #666666 0%, #0e0e0e 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#666666', endColorstr='#0e0e0e', GradientType=0); /* IE6-9 */
}

[ng\:cloak], [ng-cloak], .ng-cloak {
  display: none !important;
}

@media (max-width: 1200px) {
  .data-box {
    margin: 5px;
  }
}

@media (min-width: 1200px) {
  .data-box {
    margin: 15px;
  }
}


.data-box {
  background-color: #eee;
  border: 2px #666 solid;
  box-shadow: 6px 6px 3px #666;
  width: 400px;
  user-select: none;
  .player-image {
    height: 58px;
    width: 58px;
    object-fit: cover;
    border-radius: 50%;
  }
  .data-box-text {
    margin: 5px;
  }
  .player-name-heading {
    font-size: 27px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .hideIcon{
    opacity: 0;
  }
  .percentage {
    @extend .data-box-text;
    color: black;
    font-size: 38px;
    font-weight: bolder;
  }
  .shots-total-text {
    color: black;
    font-size: 16px;
    font-weight: bolder;
  }
  .shot-breakdown-text {
    @extend .shots-total-text;
  }
  .shots-start-end-date {
    font-size: 12px;
    color: #000;
  }
  .results-table {
    margin-top: 5px;
    margin-left: 10px;
    font-size: 18px;
    text-align: center;
    padding: 0px;
    .value-header {
      font-size: 12px;
      color: gray; // font-weight: bolder;
      text-align: center;
      padding-right: 2px;
    }
    .value {
      text-align: left; // font-weight: bolder;
    }
    .value-label {
      font-size: 12px;
      color: gray;
      text-align: left;
      padding-top: 6px;
      padding-right: 0px;
    }
    .value-number {
      text-align: right;
      padding-left: 0px;
    }
  }
}

.data-box-alt {
  @extend .data-box;
  box-shadow: none;
  background-color: #f9f9f9;
  border: 2px #d6d6d6 solid;
}

// Editable team roster
@media (max-width: 1200px) {
  .session-card {
    width: 110px;
    height: 110px;
    .image {
      height: 32px;
    }
    .name {
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .data {
      visibility: hidden;
    }
    .responsive-text {
      font-size: 9px;
    }
  }
}

@media (min-width: 1200px) {
  .session-card {
    width: 200px;
    height: 200px;
    .image {
      height: 72px;
    }
    .name {
      font-size: 18px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .responsive-text {
      font-size: 15px;
    }
  }
}

$session-card-alt-height: 48px;

.session-card-alt {
  @extend .session-card;
  width: 100%;
  height: fit-content;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  margin: 15px 0;
  border: 1px #d6d6d6 solid;
  .image {
    object-fit: contain;
    width: 100px;
    height: $session-card-alt-height;
  }
  .name {
    font-size: 12px;
    transition: .3s ease;
    white-space: normal;
  }
  .data {
    font-size: 12px !important;
    transition: .3s ease;
    visibility: visible;
  }
  .session-badge-container {
    font-size: 12px;
    transition: .3s ease;
  }
}

.session-card-alt:first-child {
  margin-top: 0;
}

.session-card-alt:last-child {
  margin-bottom: 5px;
}

.session-card-alt-container {
  display: grid;
  align-items: center;
  height: $session-card-alt-height;
  grid-template-columns: 90px 78px 52px auto;
  grid-gap: 15px;
}

// Editable team roster
.session-card {
  @extend .data-box;
  position: relative;
  padding: 10px;
  .image {
    background-color: #ddd;
    margin-bottom: 4px;
    display: block;
    transition: .3s ease;
    backface-visibility: hidden;
  }
  .middle {
    transition: .3s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%)
  }
  .session-badge {
    position: relative;
    display: table-cell;
    font-weight: 100;
    left: 0;
    max-width: 80px;
    margin: 0 0 5px 0;
    padding: 3px;
    height: 17px;
  }
  .session-auto-badge {
    @extend .session-badge;
    top: 30px;
  }
  .session-video-badge {
    @extend .session-badge;
    top: 50px;
  }
  .session-badge-container {
    position: relative;
    margin-bottom: 5px;
  }
  .session-node-badge {
    @extend .session-badge;
    top: 10px;
    width: 80px;
  }
  .name {
    padding: 5px;
  }
  .data {
    font-size: 15px;
  }
}

.session-card-disabled {
  opacity: 0.3;
}

.session-card:hover .image {
  opacity: 0.3;
}

.session-card-alt:hover .name {
  opacity: 0.3;
}

.session-card-alt:hover .data {
  opacity: 0.3;
}
.session-card-alt:hover .session-badge-container {
  opacity: 0.3;
}

.session-card:hover .middle {
  opacity: 1;
}

.cut-text {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 90%;
  height: 1.2em;
  white-space: nowrap;
}

.filter-tag-box {
  color: #327ab7;
  background-color: #ffffff;
  display: inline-block;
  padding: 6px 12px;
  -webkit-box-shadow: 3px 3px 1px 0 #757575;
  -moz-box-shadow: 3px 3px 1px 0 #757575;
  box-shadow: 3px 3px 1px 0 #757575;
  margin: 6px 3px;
  font-size: 12px;
  font-weight: 100;
  letter-spacing: 0.4px;
  border: 1.3px solid #327ab7;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: 1px;
  border-radius: 20px;
}

.filter-tag-text {
  color: #3e87c5bd;
}

.filterManualSessions {
  font-size: 12px;
  padding-left: 10px;
  cursor: pointer;
}

.filterManualSessions:hover {
  text-decoration: none;
}

.filter-tag-box [data-role='remove']::after {
  font-family: FontAwesome;
  content: "\f057";
  padding: 0 2px;
  font-size: 14px;
  vertical-align: -1px;
}

.filter-tag-box [data-role='remove'] {
  margin-left: 8px;
  cursor: pointer;
}


.video-btn-pressed, .video-btn-pressed:hover, .video-btn-pressed:focus {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #fff;
  background-color: #909090;
  border-color: #3a3a3a;
}

.fa-spinner-color {
  color: #fa7548;
}

.spinner-wrapper {
  text-align: center;
  font-size: 60px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.small-spinner-wrapper {
  text-align: center;
  font-size: 45px;
}

.no-sessions-result {
  text-align: center;
  color: #dcdcdc;
  font-size: 25px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.no-shots-result {
  text-align: center;
  color: #bababa;
  font-size: 15px;
  margin-top: 5px;
}
*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
.wrapper {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr;
  grid-gap: 10px;
  align-content: center;
  height: calc(100vh - 95px);
}

.left-container {
  grid-column: 1;
  grid-row: 1;
  display: grid;
  grid-template-rows: auto 1fr minmax(270px, 387px);
  grid-gap: 10px;
  height: calc(100vh - 95px);
  margin-top: 15px;
}
.right-container {
  grid-column: 2;
  grid-row: 1;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-gap: 10px;
  height: calc(100vh - 70px);
}
.basket-and-time-picker {
  grid-column: 1;
  grid-row: 1;
  justify-self: center;
}
.session-info {
  grid-column: 1;
  grid-row: 2;
  justify-self: center;
}
.court-origin-view {
  grid-row: 1;
  grid-column: 1;
  justify-self: left;
  align-self: end;
  width: 100%;
}
.timeline-view {
  grid-column: 1;
  grid-row: 2;
}
.rim-view {
  grid-column: 1;
  grid-row: 3;
  justify-self: center;
}

#retrospective-accordion {
  .panel {
    border-radius: 0;
    border: 2px #d6d6d6 solid;
    width: 400px;
    margin-left: 0;
    box-shadow: none;
  }
}

.row-split-display-button {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  padding: 12px;
  width: 50px;
  height: 3em;
}
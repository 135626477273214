body {
    font-family: tahoma, arial;
    color: $logo-color;
}


// Classes for highlighting modified test. Used in highlightOnChange Directive
.highlight-off {
    transition: 2.5s;
}

.highlight-on {
    background-color: rgb(255, 240, 174) !important;
    transition: 0.2s;
}
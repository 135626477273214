@font-face {
    font-family: 'digital-clock-font';
    src: url('/static/fonts/digital-7-mono-italic.ttf');
}

#video-player {
    //wdith: 100%;
   //height: 500px;
}

#video-timeline {
    margin: auto;
    width: 100%;
}

#timecode {
    position: absolute;
    top: 10px;
    left: 130px;
    font-size: 24px;
    font-family: 'digital-clock-font';
    font-weight: 700;
    background-color: #fffc;
    padding-right: 4px;
    padding-left: 4px;
}

.camera-selector-wrapper {
  position: absolute;
  top: 10px;
  right: 130px;
  font-size: 24px;
  font-weight: 700;
  background-color: #fffc;
  padding-right: 4px;
  padding-left: 4px;
}

.camera-selector {
    position: absolute;
    top: 0px;
    right: 0px;
}

.video-logo-rspct {
    position: absolute;
    top: 10px;
    left: 25px;
    height: 34px;
    background-color: #fffc;
    padding: 3px;
}

.video-container
{
  width:100%;
}

.video-container video
{
  min-width: 100%;
  max-width: 100%;
}

//video:-webkit-full-screen {
//  width: 100%;
//  height: 100%;
//  max-height: 100%;
//}

.scrollable-menu {
    height: auto;
    max-height: 800px;
    overflow-x: hidden;
}

@media (max-width: 1200px) {
    .video-card {
        width: 200px;
        height: 110px;
    }
}

@media (min-width: 1200px) {
    .camera-name {
        font-size: 14px;
        font-weight: 900;
    }
    .video-card {
        width: 220px;
        height: 150px;
    }
}

.video-card {
    @extend .data-box;
    position: relative;
    padding: 1px;
    .camera-name {
        font-size: 14px;
        font-weight: 900;
        margin-bottom: 8px;
    }
    .video {
        max-width: 90%;
        background-color: #ddd;
        margin-bottom: 4px;
        display: block;
        transition: .3s ease;
        backface-visibility: hidden;
    }
}

.filter-video-with-noir {
    filter: url(#noir) blur(0px) grayscale(80%);
}
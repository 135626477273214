.col-form-label {
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.form-row {
  padding: 10px;
}


$circle-length: 151px;
$check-length: 36px;
$circle-color: #5cb85c;

@keyframes scaleAnimation {
  0% {
    opacity: 0;
    transform: scale(1.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes drawCircle {
  0% {
    stroke-dashoffset: $circle-length;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes drawCheck {
  0% {
    stroke-dashoffset: $check-length;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#successAnimationCircle {
  stroke-dasharray: $circle-length $circle-length;
  stroke: $circle-color;
}

#successAnimationCheck {
  stroke-dasharray: $check-length $check-length;
  stroke: $circle-color;
}

#successAnimationResult {
  fill: $circle-color;
  opacity: 0;
}

#successAnimation.animated {
  animation: 1s ease-out 0s 1 both scaleAnimation;

  #successAnimationCircle {
    animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCircle,
    0.3s linear 0.9s 1 both fadeOut;
  }

  #successAnimationCheck {
    animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCheck,
    0.3s linear 0.9s 1 both fadeOut;
  }

  #successAnimationResult {
    animation: 0.3s linear 0.9s both fadeIn;
  }
}

.constrain {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.constrain-complete {
  color: #fab29b;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.btn-success {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.password-constrains {
  -webkit-transition: all .25s ease-in-out 0s;
  transition: all .25s ease-in-out 0s;
}

.password-constrains.ng-hide, .password-secured.ng-hide {
  opacity: 0;
}

.password-constrains.ng-hide-remove, .password-constrains.ng-hide-add,
.password-secured.ng-hide-remove, .password-secured.ng-hide-add {
  display: block !important;
}

.password-constrains.ng-hide-remove {
  -webkit-transition: all linear 0.3s;
  transition: all linear 0.3s;
  transition-delay: 0.3s;
}

.password-secured.ng-hide-remove {
  -webkit-transition: all linear 0.3s;
  transition: all linear 0.3s;
  transition-delay: 0.3s;
}

.password-constrains.ng-hide-add, .password-secured.ng-hide-add {
  -webkit-transition: all linear 0.3s;
  transition: all linear 0.3s;
}

.password-secured {
  font-size: 14px;
  opacity: 0.8;
}

.under-password-text-area {
  margin-left: 23px;
  border: 1px #fab29b solid;
  padding: 10px;
  border-radius: 6px;
}

.clickable {
  cursor: pointer;
}

.forgot-password-small-text {
  font-size: 11px;
  display: block;
  color: #fa7448;
  opacity: 0.8;
}